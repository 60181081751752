import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import VideoEmbed from '../../components/VideoEmbed/VideoEmbed';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import IntroBanner from 'components/CustomComponents/Banners/IntroBanner';
import BgBlack from 'components/CustomComponents/Backgrounds/BgBlack';

import Diamond from './images/Icons-Diamond@2x.png';
import Bonus from './images/Icons-Bonus@2x.png';
import Core from './images/Icons-Core@2x.png';
import Gold from './images/Icons-Gold@2x.png';
import Platinum from './images/Icons-Platinum@2x.png';
import Ranging from './images/Icons-Ranging@2x.png';
import Silver from './images/Icons-Silver@2x.png';
import Spend from './images/Icons-Spend@2x.png';

const ProductsContainer = styled.div`
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .products--link {
    text-decoration: none;
    color: #fff;
    background-color: ${thd('primary', '#000')};
    padding: 10px 50px 10px 50px;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <div>
          <IntroBanner
            pageTitle={
              <React.Fragment>
                Welcome to the
                <br />
                <span className="text--primary">
                  Anchor Partners Plus Program
                </span>
              </React.Fragment>
            }
          />
        </div>
        <BgBlack>
          <Grid>
            <Row justifyContent="center">
              <Col md={8} className="text--center">
                <p>
                  The new-look program that rewards distributors for their
                  continued support of Anchor™ Food Professionals products. The
                  How to Achieve section below outlines the various ways you can
                  be rewarded and what you will earn. Each month you will be
                  awarded Tokens based on your achievement against set criteria.
                  These Tokens can be redeemed for a huge range of fantastic
                  rewards.
                </p>
                <p>
                  Your Anchor FP Sales Representative will advise your targets
                  each quarter.
                  <br />
                  You can track your earnings and progress on the statement
                  page.
                </p>
                <p>
                  Each distributor has been allocated a tier. The earning
                  potential of these tiers against set criteria each month is
                  detailed below. Talk to your Anchor FP Sales Representative
                  about how you can be promoted to the next tier.
                </p>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col xs={6} sm={4} className="text--center">
                <img src={Diamond} alt="Diamond Tier" height={80} />
                <p>Diamond Tier: 7 Tokens</p>
              </Col>
              <Col xs={6} sm={4} className="text--center">
                <img src={Platinum} alt="Platinum Tier" height={80} />
                <p>Platinum Tier: 5 Tokens</p>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col xs={6} sm={4} className="text--center">
                <img src={Gold} alt="Gold Tier" height={80} />
                <p>Gold Tier: 3 Tokens</p>
              </Col>
              <Col xs={6} sm={4} className="text--center">
                <img src={Silver} alt="Silver Tier" height={80} />
                <p>Silver Tier: 2 Tokens</p>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col className="text--center">
                <hr />
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col sm={8} className="text--center">
                <div>
                  <VideoEmbed
                    videoId="1nigC3VXTDs"
                    title="Anchor Partners Plus Program"
                  />
                </div>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col sm={8} className="text--center">
                <h2>
                  How to <span className="text--primary">Achieve</span>
                </h2>
                <p>
                  <img src={Core} alt="Core Product Ranging" height={90} />
                </p>
                <h4>Core Product Ranging</h4>
                <p>
                  You will be set an individual number of products ranged target
                  at the beginning of each quarter. For each month you achieve
                  this target you will be awarded{' '}
                  <PrivateComponent
                    requiredRoles={['Diamond', 'Administrator']}
                  >
                    7 Tokens
                  </PrivateComponent>
                  <PrivateComponent
                    requiredRoles={[
                      'Platinum',
                      'AccountManager',
                      'Client',
                      'ClientRepresentative',
                      'DataAnalyst',
                      'Procurement',
                    ]}
                  >
                    5 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Gold']}>
                    3 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Silver']}>
                    2 Tokens
                  </PrivateComponent>
                  . Your baseline target will be refreshed each quarter.{' '}
                  <Link to="/products/">View products</Link>
                </p>
                <p className="spacer">
                  <img src={Ranging} alt="Additional Ranging" height={120} />
                </p>
                <h4>Additional Ranging</h4>
                <PrivateComponent
                  requiredRoles={[
                    'Platinum',
                    'Gold',
                    'Silver',
                    'AccountManager',
                    'Client',
                    'ClientRepresentative',
                    'DataAnalyst',
                    'Procurement',
                  ]}
                >
                  <p>
                    For every additional product you range in a month over your
                    individual number of products ranged target your{' '}
                    <PrivateComponent requiredRoles={['Platinum']}>
                      5 Tokens
                    </PrivateComponent>
                    <PrivateComponent requiredRoles={['Gold']}>
                      3 Tokens
                    </PrivateComponent>
                    <PrivateComponent requiredRoles={['Silver']}>
                      2 Tokens
                    </PrivateComponent>{' '}
                    will be multiplied as follows:
                    <br />
                    +1 product = x 2
                    <br />
                    +2 or more products = x 3
                    <br />
                    <div className="text--small">
                      NB: Minimum purchase of 30 cartons per additional product
                      per quarter is required to qualify. Tokens achieved for
                      Additional Ranging will be awarded at the end of the
                      quarter for each month achieved.
                    </div>
                  </p>
                </PrivateComponent>
                <PrivateComponent requiredRoles={['Diamond', 'Administrator']}>
                  <p>
                    As a Diamond Tier customer, maintain your individual number
                    of products ranged
                    <br />
                    each month and you will qualify for the additional ranging
                    multiplier
                    <br />
                    of x2 your 7 tokens.
                    <br />
                    <div className="text--small">
                      NB: Tokens achieved for Additional Ranging will be awarded
                      <br />
                      at the end of the quarter for each month achieved.
                    </div>
                  </p>
                </PrivateComponent>
                <p className="spacer">
                  <img src={Spend} alt="Invoice Spend" height={90} />
                </p>
                <h4>Invoice Spend</h4>
                <p>
                  You will be set an invoice spend target at the beginning of
                  each quarter. For each month you maintain or exceed this
                  target you will be awarded{' '}
                  <PrivateComponent
                    requiredRoles={['Diamond', 'Administrator']}
                  >
                    7 Tokens
                  </PrivateComponent>
                  <PrivateComponent
                    requiredRoles={[
                      'Platinum',
                      'AccountManager',
                      'Client',
                      'ClientRepresentative',
                      'DataAnalyst',
                      'Procurement',
                    ]}
                  >
                    5 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Gold']}>
                    3 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Silver']}>
                    2 Tokens
                  </PrivateComponent>
                  .
                </p>
                <p className="spacer">
                  <img src={Bonus} alt="Bonus Opportunity" height={120} />
                </p>
                <h4>Bonus Opportunity</h4>
                <p>
                  Look out for opportunities to earn additional tokens. This may
                  include new product launches, buy show volume targets or
                  focused category opportunities.
                </p>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col className="text--center">
                <hr />
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col sm={8} className="text--center">
                <ProductsContainer>
                  <h2>
                    Participating{' '}
                    <span className="text--primary">Products</span>
                  </h2>
                  <p className="spacer--small--bottom">
                    The range of core products from each priority category can
                    be <Link to="/products/">viewed here</Link>.
                    <br />
                    The full range of{' '}
                    <strong>
                      Anchor<sup>™</sup> Food Professionals
                    </strong>{' '}
                    products can be seen on{' '}
                    <a
                      href="https://www.anchorfoodprofessionals.com/au/en/our-products.html"
                      target="_blank"
                      title="Anchor Food Professionals"
                      rel="noopener noreferrer"
                    >
                      our website
                    </a>
                    .
                  </p>
                  <p>
                    <Link to="/products/" className="products--link ">
                      View Participating Products
                    </Link>{' '}
                  </p>
                </ProductsContainer>
              </Col>
            </Row>
          </Grid>
        </BgBlack>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
