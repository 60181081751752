import { styled, th } from '@smooth-ui/core-sc';

const BgBlack = styled.section`
  align-items: center;
  background-color: ${th('black')};
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default BgBlack;
