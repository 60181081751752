import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, css, styled, thd, up } from '@smooth-ui/core-sc';
import bannerMobile from './images/AnchorPlus-Mobile-Header@1x.png';
import bannerMobile2x from './images/AnchorPlus-Mobile-Header@2x.png';
import bannerDesktop from './images/AnchorPlus-Desktop-Header@1x.png';
import bannerDesktop2x from './images/AnchorPlus-Desktop-Header@2x.png';

const BannerWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const BannerContainer = styled.div`
  background-image: url(${bannerMobile});
  align-items: center;
  background-color: ${thd('primary', '#00A7AD')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 250px;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bannerMobile2x});
  }

  .banner__title {
    color: #fff;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
  }
  .banner {
    height: 150px;
  }
  ${up(
    'sm',
    css`
      background-image: url(${bannerDesktop});
      height: 210px;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerDesktop2x});
      }

      .banner {
        height: 200px;
      }
    `
  )}
`;

const IntroBanner = props => (
  <BannerWrapper className="banner-wrapper">
    <BannerContainer {...props}>
      <Grid>
        <Row justifyContent="center" alignItems="center" className="banner">
          <Col xs={12} md={8} className="text--center">
            <p className="banner__title">{props.pageTitle}</p>
          </Col>
        </Row>
      </Grid>
    </BannerContainer>
  </BannerWrapper>
);

IntroBanner.propTypes = {
  pageTitle: PropTypes.object.isRequired,
};

export default IntroBanner;
